<!--
 * @Descripttion: 
 * @version: 
 * @Author: xyh
 * @Date: 2021-07-13 16:38:06
 * @LastEditTime: 2021-07-15 17:58:57
-->
<template>
  <div class="wx-login" v-show="showQRcode">
    <div class="wx-login-container">
      <div class="close-arrow">
        <a-icon
          @click="closeWxCode"
          type="close"
          style="color: #ffffff; font-size: 18px; cursor: pointer"
        />
      </div>
      <div class="wx-login-content">
        <!-- <div class="wx-login-title">二维码邀请</div> -->
        <div class="wx-login-footer" style="padding-bottom: 10px">
          任务邀请二维码
        </div>
        <!-- 二维码 -->
        <div class="wx-code"><div id="qrcode" ref="qrCodeDiv"></div></div>
        <div class="wx-login-save-pic" @click="downLoadQrCode">保存图片</div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  props: {
    taskId: {
      type: String,
      require: true,
    },
    payMode: {
      type: String || Number,
      require: true,
      default: "10",
    },
    showQRcode: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  data() {
    return {
      imgData: "",
      qrcodeDivCreate: null,
    };
  },
  mounted () {
      this.bindQRCode();
  },
  
  methods: {
      closeWxCode(){
          this.$emit('closeQRcode',false);
      },
    //邀请人员二维码
    bindQRCode() {
      if (this.qrcodeDivCreate) {
        return;
      }
      this.qrcodeDivCreate = new QRCode(this.$refs.qrCodeDiv, {
        text: `${window._CONFIG['inviteUrl']}/#/pages/taskHall/taskDetail?taskId=${this.taskId}&payMode=${this.payMode}&tag=fslyPc`,
        width: 282,
        height: 282,
        colorDark: "#333333", // 二维码颜色
        colorLight: "#ffffff", // 二维码背景色
        correctLevel: QRCode.CorrectLevel.L, // 容错率，L/M/H
      });
      // this.createPicture() // 二维码生成后，执行生成图片
    //   console.log(this.qrcodeDivCreate);
      let canvas = this.qrcodeDivCreate._el.querySelector("canvas"); //获取生成二维码中的canvas，并将canvas转换成base64
      let base64Text = canvas.toDataURL("image/png");
      this.imgData = base64Text;
    },

    downLoadQrCode() {
      const imgUrl = this.imgData;
      // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
      if (window.navigator.msSaveOrOpenBlob) {
        var bstr = atob(imgUrl.split(",")[1]);
        var n = bstr.length;
        var u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        var blob = new Blob([u8arr]);
        window.navigator.msSaveOrOpenBlob(
          blob,
          "分时领英任务邀请" + "." + "png"
        );
      } else {
        // 这里就按照chrome等新版浏览器来处理
        const a = document.createElement("a");
        a.href = imgUrl;
        a.setAttribute("download", "分时领英任务邀请");
        a.click();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wx-login {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  .wx-login-container {
    width: 729px;
    height: 500px;
    .close-arrow {
      display: flex;
      flex-direction: row-reverse;
      margin-bottom: 20px;
    }
    .wx-login-content {
      width: 400px;
      height: 400px;
      // background: #FFFFFF;
      margin: 0 auto;
      position: relative;
      .wx-login-title {
        text-align: center;
        font-size: 20px;
        color: #ffffff;
        margin-bottom: 20px;
      }
      .wx-code {
        width: 322px;
        height: 322px;
        background: #ffffff;
        padding: 20px;
        margin: 0 auto;
      }
      .wx-login-footer {
        font-size: 12px;
        color: #ffffff;
        text-align: center;
        margin-top: 20px;
      }
      .wx-login-save-pic {
        cursor: pointer;
        font-size: 12px;
        color: #ffffff;
        text-align: center;
        margin-top: 20px;
      }
      .wx-login-reget {
        position: absolute;
        top: 0;
        width: 400px;
        height: 430px;
        background: rgba(255, 255, 255, 0.9);
        z-index: 60;
        display: flex;
        justify-content: center;
        align-items: center;
        .wx-login-getbtn {
          background: #3c58f2;
          width: 200px;
          height: 40px;
          font-size: 14px;
          padding: 10px;
        }
      }
    }
  }
}
</style>