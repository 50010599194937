<!--
 * @Descripttion: 
 * @version: 
 * @Author: xyh
 * @Date: 2021-05-12 20:43:13
 * @LastEditTime: 2021-05-20 18:08:39
-->
<!-- 抽屉 -->

<template>
  <div class="drawer" v-if="drawerFlag">
    <div class="guide_tip" v-if="guideShow">
      <div class="guide_box">
        <div class="guide_text" @click="guideShow = false">
          <a-icon type="left" style="color: #ffffff" />指引
        </div>
      </div>
      <!-- <div class="close_icon" @click="drawerFlagClose"><img src="../../assets/task_process/close_icon.png" alt=""></div> -->
    </div>
    <div v-else class="drawer_tip">
      <div class="drawer_menu">
        <div class="menu_title">分时领英任务流程</div>
        <div
          class="menu_nav"
          @click="navChange(1)"
          :class="navIndex === 1 ? 'menu_nav_active' : ''"
        >
          单次任务+个人上传
        </div>
        <div
          class="menu_nav"
          @click="navChange(2)"
          :class="navIndex === 2 ? 'menu_nav_active' : ''"
        >
          单次任务+企业上传
        </div>
        <div
          class="menu_nav"
          @click="navChange(3)"
          :class="navIndex === 3 ? 'menu_nav_active' : ''"
        >
          长期任务+个人上传
        </div>
        <div
          class="menu_nav"
          @click="navChange(4)"
          :class="navIndex === 4 ? 'menu_nav_active' : ''"
        >
          长期任务+企业上传
        </div>
      </div>
      <div class="drawer_content">
        <div class="close_icon">
          <div></div>
          <div class="icon">
            <!-- <span class="icon_sub"><a-icon type="minus" style="color:#fff;" @click="guideShowChange"/></span> -->
            <span style="cursor: pointer"
              ><a-icon
                type="close"
                style="color: #fff"
                @click="guideShowChange"
            /></span>
          </div>
        </div>

        <div class="content_img" v-if="navIndex === 1">
          <img src="../../assets/task_process/task_process1.png" alt="" />
        </div>
        <div class="content_img" v-if="navIndex === 2">
          <img src="../../assets/task_process/task_process2.png" alt="" />
        </div>
        <div class="content_img" v-if="navIndex === 3">
          <img src="../../assets/task_process/task_process3.png" alt="" />
        </div>
        <div class="content_img" v-if="navIndex === 4">
          <img src="../../assets/task_process/task_process4.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["type"],
  data() {
    return {
      navIndex: 1,
      guideShow: false,
      drawerFlag: true,
      currentTips: "",
    };
  },
  created() {
    let pageTips = window.localStorage.getItem("allTipsFlag");
    if (!pageTips) {
      let obj = {
        addTaskTips: 1,
        invite_danci: 1,
        invite_duoci: 1,
        drawer_danci: 1,
        drawer_duoci: 1,
        settlement_danci: 1,
        settlement_duoci: 1,
      };
      
      
      window.localStorage.setItem("allTipsFlag", JSON.stringify(obj));
      this.guideShow = true;
    } else {
      this.currentTips = JSON.parse(pageTips)[this.type];
      if (this.currentTips == 0) {
        this.guideShow = false;
      }
      if (this.currentTips == 1) {
        this.guideShow = true;
      }
    }
  },
  methods: {
    navChange(index) {
      this.navIndex = index;
    },
    drawerFlagClose() {
      this.drawerFlag = false;

      this.currentTips = 1;
      let allTips = window.localStorage.getItem("allTipsFlag");
      allTips = JSON.parse(allTips);
      allTips[this.type] = 1;

      window.localStorage.setItem("allTipsFlag", JSON.stringify(allTips));
    },
    guideShowChange() {
      this.guideShow = true;
      if (this.currentTips == 0) {
        this.currentTips = 1;
        let allTips = window.localStorage.getItem("allTipsFlag");
        allTips = JSON.parse(allTips);
        allTips[this.type] = 1;

        window.localStorage.setItem("allTipsFlag", JSON.stringify(allTips));
      }
    },
  },
};
</script>

<style lang="less" scoped>
.drawer {
  z-index: 99999;
  .guide_tip {
    position: fixed;
    right: 0;
    bottom: 120px;
    background: #666666;
    padding: 10px 20px;
    color: #fff;
    font-size: 14px;
    z-index: 9999;
    border-radius: 20px 0px 0px 20px;
    cursor: pointer;
    .close_icon {
      position: absolute;
      left: 0;
      top: -11px;
    }
  }
  .drawer_tip {
    display: flex;
    width: 100%;
    height: 380px;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #4c4c4c;
    z-index: 9999;
  }
  .drawer_menu {
    width: 226px;
    height: 380px;
    background: #333333;
    .menu_title {
      font-size: 22px;
      font-weight: bold;
      color: #999999;
      text-align: center;
      height: 80px;
      line-height: 80px;
    }
    .menu_nav {
      color: #b3b3b3;
      font-size: 14px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      box-sizing: border-box;
      cursor: pointer;

      &:hover {
        color: #ffad33;
      }
    }
    .menu_nav_active {
      border-left: 2px solid #ffad33;
      color: #ffad33;
      background: #4a4a4a;
      box-sizing: border-box;
    }
  }
  .drawer_content {
    padding: 20px 50px 0;
    flex: 1;
    position: relative;
    .close_icon {
      display: flex;
      justify-content: space-between;
      .icon {
        .icon_sub {
          padding-right: 10px;
          cursor: pointer;
        }
      }
    }

    .content_img {
      width: 1017px;
      height: 312px;
    }
  }
}
</style>